import Home from "./views/Home.vue";
 
export default {
    "mode": "history",
    "base": process.env.NODE_ENV === "production" ? `/${process.env.VUE_APP_APP}/` : "/",
    "routes": [{
        "path": "/",
        "name": "Home",
        "component": Home
    }]
};

<template>
  <div class="flex flex-col bg-white rounded shadow-lg p-4 m-4">
    <div class="flex flex-row items-center">
      <div class="mx-2">
        <img style="min-width: 160px" :src="app.appimage" width="160" height="90">
      </div>
      <div class="flex flex-row items-center w-full">
        <h4 class="p-2 font-bold text-grey flex-1" style="margin-bottom: 0">{{app.name}}</h4>
        <div class="flex flex-row items-center p-2 cursor-pointer" v-if="app.tags.vpn">
          <p class="text-white font-bold rounded border-solid border-1 bg-dark-orange p-1">VPN</p>
        </div>
      </div>
    </div>
    <p class="p-2 text-grey flex-1">{{app.description}}</p>
    <div class="flex flex-row">
      <span class="flex-grow"></span>
      <button class="bg-primary-light" @click="nav(app.route)">Launch</button>
    </div>
  </div>
</template>
<script>
export default {
  "name": "AppCard",
  "props": {
    "app": {
      "type": Object
    }
  },
  "methods": {
    nav(route) {
      window.open(route);
    }
  }
};
</script>

<template>
  <div class="container mx-auto">
    <div class="bg-white rounded m-4 p-0">
      <div class="bg-cover bg-center h-full p-4 rounded-t" :style="{'background-image': `url(${require('@/assets/images/banner.jpg')})`}">
        <h1 class="text-white text-xl">HR Apps Portal</h1>
      </div>
      <Loading message="Loading Applications" v-if="loaded" />
      <div class="p-4" v-if="!loaded">
        <BaseInput label="Search Applications" v-model="filterText" />
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid-gap-4">
      <AppCard v-for="app in filteredApps" :key="app.id" :app="app" />
    </div>
  </div>
</template>
<script>
export default {
  "name": "Home",
  data() {
    return {
      "filterText": ""
    };
  },
  "computed": {
    loaded() {
      return this.$store.state.apps.length <= 0;
    },
    filteredApps() {
      const allApps = this.$store.state.apps;
      if (!this.filterText || this.filterText === "") {
        return allApps.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
      } else return allApps.filter(app => {
        return app.name.toLowerCase().includes(this.filterText.toLowerCase());
      }).sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
    }
  }
};
</script>

import Vue from "vue";
import App from "./App.vue";
import routerConfig from "./router";
import "./registerServiceWorker";
import { HrAppKit } from "@pta/hr-apps-kit";
import "@pta/hr-apps-kit/dist/css/main.css";
 import AppCard from "@/components/AppCard.vue";
window.localStorage.setItem("pailappredirecturl", process.env.VUE_APP_APP);
 
HrAppKit.install(Vue, {
    "storeConfig": {
        "apps": []
    },
    "dbConfig": {
        "url": process.env.VUE_APP_DB_URL,
        "appId": process.env.VUE_APP_DB_ID
    },
    routerConfig
});
 
Vue.config.productionTip = false;
  Vue.component("AppCard", AppCard);
new Vue({
"router": Vue.prototype.$hakRouter,
"store": Vue.prototype.$hakStore,
"render": h => h(App),
}).$mount("#app");

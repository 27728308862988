<template>
    <div id="app">
        <Toolbar :headers="toolbarHeaders" app="HR Apps Portal" pta/>
        <router-view />
        <Toaster ref="toaster" />
    </div>
</template>
<script>
 
import {
    rtdb
} from "@pta/hr-apps-kit";
 
export default {
    "name": "app",
    "mixins": [rtdb],
    mounted() {
        this.connect(this.connected);
    },
    data() {
        return {
            "toolbarHeaders": []
        };
    },
    "methods": {
      async connected() {
        let apps = await this.$db.apps.apps();
        console.log(apps);
        this.$storeData("apps", apps);
      }
    }
};
</script>
